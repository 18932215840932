<nav class="navbar-expand-md navbar-custom">
  <div class="d-flex justify-content-between mt-2">
    <div>
      <div *ngIf="mobile" class="p-2">
        <button
          (click)="change()"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <img
            class="icon open"
            *ngIf="status"
            src="assets/images/Hamburger.svg"
            alt="open"
          />
          <img
            class="icon"
            *ngIf="!status"
            src="assets/images/HamburgerClose.svg"
            alt="close"
          />
        </button>

        <div class="collapse navbar-collapse navbar-default" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link navbar-text"
                title="FAQs"
                routerLink="/faq-section"
                >FAQs</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link navbar-text"
                routerLink="/demo-videos"
                title="Demo Videos"
                >Demo Videos</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link navbar-text"
                [href]="urls.TEAMS_HELP_CHANNEL"
                target="_blank"
                title="Help Channel"
                >Help Channel</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link navbar-text"
                routerLink="/bug-report"
                title="Report Bug"
                >Report Bug</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div
        class="navbar-brand navbar-text ml-3 pt-2"
        [ngClass]="{ mobile: mobile }"
        title="Contripoint"
        routerLink="/main-dashboard"
      >
        <img [ngClass]="{ logo: mobile }" src="assets/images/n_logo.svg" />
      </div>
    </div>

    <div class="pointer d-flex justify-content-end p-3">
      <div
        *ngIf="!mobile"
        class="d-flex w-60 justify-content-center mx-3"
        [matMenuTriggerFor]="informationDesk"
      >
        <span class="information">Information Desk</span>
        <img
          class="down-arrow"
          src="./assets/images/menu-arrow.svg"
          alt="down arrow"
        />
      </div>
      <div class="mx-3">
        <img
          title="Checkout"
          src="./assets/images/checkout-icon.svg"
          alt="checkout"
          routerLink="/checkout"
        />
      </div>
      <div class="mx-3">
        <mat-icon
          [matBadgeHidden]="commonService.getNewNotificationCount() < 1"
          matBadge="{{ commonService.getNewNotificationCount() }}"
          matBadgeColor="warn"
        >
          <img
            class="pb-3"
            alt="notifications"
            title="Notification"
            src="assets/images/n_notification.svg"
            (click)="getNewNotification('NEW')"
            [matMenuTriggerFor]="msg"
            (menuClosed)="menuClosed()"
            #menuBtn1
          />
        </mat-icon>
      </div>

      <div class="border-left">
        <img
          class="menu"
          title="Logout"
          (click)="openLogoutModal()"
          src="assets/images/n_logout.svg"
          alt="logout"
        />
      </div>
      <mat-menu #informationDesk="matMenu">
        <button class="menu-items" mat-menu-item routerLink="/faq-section">
          FAQs
        </button>
        <button class="menu-items" mat-menu-item routerLink="/demo-videos">
          Demo Videos
        </button>
        <button class="menu-items" mat-menu-item>
          <a [href]="urls.TEAMS_HELP_CHANNEL" target="_blank"> Help Channel </a>
        </button>
        <button class="menu-items" mat-menu-item routerLink="/bug-report">
          Report Bug
        </button>
      </mat-menu>

      <mat-menu
        #msg="matMenu"
        xPosition="before"
        backdropClass="custClass"
        class="my-class"
      >
        <div
          *ngIf="notifyData && notifyData.length > 0"
          class="notification-container"
        >
          <h1 class="heading">NOTIFICATIONS</h1>
          <mat-divider class="w-100"></mat-divider>
          <div class="d-flex flex-nowrap bd-highlight">
            <div class="p-2 bd-highlight notification-count">
              {{ commonService.getNewNotificationCount() }} New Notifications
            </div>
            <div class="p-2 bd-highlight">
              <p (click)="refresh($event)" class="view-text ptr">Refresh</p>
            </div>
          </div>
          <mat-divider class="w-100"></mat-divider>
          <ul class="ul-notification" *ngFor="let notification of notifyData">
            <div
              class="d-flex flex-nowrap bd-highlight notification-row cursor-styling"
              (click)="
                notificationRedirection(
                  notification.type,
                  notification.eventId,
                  notification.eventType
                )
              "
            >
              <div class="p-2 bd-highlight" style="width: 19%">
                <img
                  [src]="notificationImageMap[notification.type]"
                  *ngIf="notificationImageMap[notification.type]"
                />
              </div>
              <div class="bd-highlight" style="width: 68%">
                <div class="p-1 bd-highlight header-text">
                  {{ notification.type }}
                </div>
                <div class="p-1 bd-highlight notification-text">
                  {{ notification.notificationMessage }}
                </div>
              </div>
              <div class="p-2 bd-highlight" style="width: 17%">
                <div class="bd-highlight time-date">
                  <p *ngIf="notification.date === currentDate">
                    {{ notification.time }}
                  </p>
                  <p *ngIf="notification.date !== currentDate">
                    {{ notification.date | date : 'dd' }}/{{
                      notification.date | date : 'MM'
                    }}/{{ notification.date | date : 'YY' }}
                  </p>
                </div>
                <div class="bd-highlight image-container">
                  <img
                    *ngIf="notification.read === '1'"
                    src="assets/images/Ellipse 55.svg"
                  />
                </div>
              </div>
            </div>
            <mat-divider class="w-100"></mat-divider>
          </ul>
        </div>
        <ul class="ul-notification">
          <div
            *ngIf="navBool === false && notifyData && notifyData.length < 1"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ul-notification"
          >
            <img
              class="example-notification"
              src="assets/images/amgub-xm6fd.svg "
            />
          </div>
        </ul>
        <div
          *ngIf="notifyData && notifyData.length > 4 && navBool === true"
          class="row"
        >
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 centre_align mb-2"
          >
            <button
              class="view_more"
              (click)="getNewNotification('MORE', $event)"
            >
              VIEW MORE
            </button>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
</nav>
