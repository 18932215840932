import { genericDailogData } from './../shared/models/confirmation-modal.model';
import {
  SetFullServerStatus,
  SetLoginServerStatus,
  Text,
} from './../shared/models/common-service.model';
import { InterviewService } from './../modules/interview/services/interview.service';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { feedback_data } from '../shared/models/client-feedback.model';
import { FeedbackTableService } from '../modules/client-feedback/service/feedback-table.service';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { dailogData } from '../shared/models/confirmation-modal.model';
import { CertificateTableService } from '../modules/certificate/service/certificate-table.service';
import { Subject, Observable, BehaviorSubject, from } from 'rxjs';
import { session_data } from '../shared/models/training-session.model';
import { project_data } from '../modules/project/project-table/project.model';
import { interview_data } from '../shared/models/interview.model';
import { ProjectService } from '../modules/project/service/project.service';
import { MentorshipService } from '../modules/mentorship/service/mentorship.service';
import { mentorship_data } from '../modules/mentorship/mentorship-table/mentorship.model';
import { TeamBuildingService } from '../modules/team-building/service/team-building.service';
import { team_building_data } from '../shared/models/team-building.model';
import { SelfDevelopmentTableService } from '../modules/self-development/service/self-development-table.service';
import { self_dev_data } from '../shared/models/self-development.model';
import { EventService } from '../modules/events/services/event.service';
import { CommonModalComponent } from '../shared/components/common-modal/common-modal.component';
import { bug_data } from '../modules/bug-report/bug-report-table/bug-report.model';
import { BugReportService } from '../modules/bug-report/services/bug-report.service';
import { CountFunctionalityService } from '../modules/dashboard/dash-cards/service/count-functionality.service';
import { NumberInput } from '@angular/cdk/coercion';
import { Count } from './../shared/models/common-service.model';
import {
  FullServerDownDetails,
  PartialServerDownDetails,
} from '../shared/models/server-down.model';
import { take } from 'rxjs/operators';
import { TrainingandsessionsService } from '../modules/trainingandsessions/service/trainingandsessions.service';
import { NominationService } from '../modules/hr-recommendation/services/nomination.service';
import { NominationStatus } from '../shared/models/nomination-award-type.model';
import { QuarterSelectionModalResponse } from '../shared/models/quater-selection.model';
import * as moment from 'moment';
import { List } from '../shared/models/event.model';
import { TimeData } from '../shared/models/transaction.model';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  directNomination: boolean;
  editedVertical: string;
  currentRoute: string;

  myData = [];
  dataDialog: dailogData;
  joyRideStepName: string;
  selectedTabValue: NumberInput;
  welcomeGifClosed: boolean;
  iseventDataFound: boolean;
  firstTimeUser: boolean;
  printMaxDates: string;
  printMinDates: string;
  displayedPrintMaxDates: string;
  displayedPrintMinDates: string;
  currentDate: Date;
  minDate: string;
  maxDate: string;
  displayedMaxDate: string;
  displayedMinDate: string;
  formMaxDate: string;
  formMinDate: string;
  contributionCount: Count;
  currentCount: Count;
  totalCount: number;
  nomineeData: string;
  draftEditNominateData: any;
  nominateEdit: boolean;
  ecdc: string;
  cochair: boolean;
  triggernumber: number;
  nominationSideBarStatus: boolean;
  genericDailogData: genericDailogData;
  previousQuarter: string;
  private maintainencePage: PartialServerDownDetails = {
    isWorking: false,
    serverImg: '../../../../assets/images/under-maintainence-img.svg',
  };
  private serverLittleDown: PartialServerDownDetails = {
    isWorking: false,
    serverImg: '../../../../assets/images/partial-dynamic-server-page.svg',
  };
  private serverFullDown: FullServerDownDetails = {
    userTrackingApiWorking: true,
    notificationApiWorking: true,
    serverImg: '../../../../assets/images/full-dynamic-server-page.svg',
  };
  private notificationCount: number;
  private newNotification: number;
  isLoaderhidden: boolean;
  private activityUpdate = new Subject<Text>();
  private eventUpdate = new Subject<Text>();
  isAdminPortalOpened: boolean;
  isAdminSideNavOpened: boolean;
  isBudgetingAddBtnClicked: boolean;
  private defaultYear: string;
  directNominationVertical: string;
  isLeaderboardOpen: boolean;
  getNominationStatus: BehaviorSubject<NominationStatus> =
    new BehaviorSubject<NominationStatus>({} as NominationStatus);
  constructor(
    private nominationService: NominationService,
    private countFunctionalityService: CountFunctionalityService,
    private interviewService: InterviewService,
    private feedbackService: FeedbackTableService,
    private certificateService: CertificateTableService,
    private trainingService: TrainingandsessionsService,
    private selfDevService: SelfDevelopmentTableService,
    private projectService: ProjectService,
    private mentorshipService: MentorshipService,
    private teamBuildingService: TeamBuildingService,
    private eventService: EventService,
    private bugService: BugReportService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
    this.directNominationVertical = '';
    this.directNomination = false;
    this.editedVertical = '';
    this.previousQuarter = '';
    this.currentRoute = '';
    this.triggernumber = 0;
    this.currentCount = {} as Count;
    this.totalCount = 0;
    this.selectedTabValue = 0;
    this.ecdc = '';
    this.maxDate = '';
    this.minDate = '';
    this.displayedMaxDate = '';
    this.displayedMinDate = '';
    this.formMaxDate = '';
    this.formMinDate = '';
    this.nominateEdit = false;
    this.isAdminPortalOpened = false;
    this.dataDialog = { title: '' };
    this.joyRideStepName = '';
    this.cochair = false;
    this.welcomeGifClosed = false;
    this.iseventDataFound = false;
    this.firstTimeUser = false;
    this.isLoaderhidden = true;
    this.currentDate = new Date();
    this.printMaxDates = '';
    this.printMinDates = '';
    this.displayedPrintMaxDates = '';
    this.displayedPrintMinDates = '';
    this.notificationCount = 0;
    this.nomineeData = '';
    this.newNotification = 0;
    this.isAdminSideNavOpened = true;
    this.isBudgetingAddBtnClicked = false;
    this.nominationSideBarStatus = false;
    this.isLeaderboardOpen = false;
    this.genericDailogData = { title: '', img: '', heading: '' };
    this.contributionCount = {
      interviewCount: 0,
      trainingCount: 0,
      certificationCount: 0,
      mentorshipCount: 0,
      selfDevelopment: 0,
      projectCount: 0,
      teamBuildingCount: 0,
      feedbackCount: 0,
    } as Count;
    this.defaultYear = '';
  }
  setDefaultYear(val: string): void {
    this.defaultYear = val;
  }
  getDefaultYear(): string {
    return this.defaultYear;
  }

  getNum() {
    return this.triggernumber;
  }
  setNum(val: number) {
    this.triggernumber = val;
  }
  setBudgetAddBtnClicked(val: boolean): void {
    this.isBudgetingAddBtnClicked = val;
  }
  getBudgetAddBtnClicked(): boolean {
    return this.isBudgetingAddBtnClicked;
  }
  getContributionCount(): Count {
    return this.contributionCount;
  }
  setEcDc(value: string) {
    this.ecdc = value;
  }
  getEcDc() {
    return this.ecdc;
  }
  getCurrentRoute() {
    return this.currentRoute;
  }
  setCurrentRoute(currentRoute: string) {
    this.currentRoute = currentRoute;
  }
  setCoChair(value: boolean) {
    this.cochair = value;
  }
  getCoChair(): boolean {
    return this.cochair;
  }

  setContributionCount(data: Count) {
    this.contributionCount = data;
  }
  setNewNotificationCount(val: number): void {
    this.newNotification = val;
  }
  getNewNotificationCount(): number {
    return this.newNotification;
  }
  setNotificationCount(val: number): void {
    this.notificationCount = val;
  }
  getNotificationCount(): number {
    return this.notificationCount;
  }
  setLoaderHidden(val: boolean): void {
    this.isLoaderhidden = val;
  }
  setEdit(val: boolean) {
    this.nominateEdit = val;
  }
  getEdit() {
    return this.nominateEdit;
  }
  getLoaderHidden(): boolean {
    return this.isLoaderhidden;
  }
  getMaintainencePageStatus() {
    return this.maintainencePage;
  }
  setFullServerStatus(data: SetFullServerStatus): void {
    this.serverFullDown = data;
  }
  getFullServerStatus(): SetFullServerStatus {
    return this.serverFullDown;
  }
  setLoginServerStatus(data: SetLoginServerStatus) {
    this.serverLittleDown = data;
  }
  getLoginServerStatus(): SetLoginServerStatus {
    return this.serverLittleDown;
  }
  setEmails(data: any) {
    this.myData = data;
  }
  getEmails() {
    return this.myData;
  }
  getNominationSideBarStatus() {
    return this.nominationSideBarStatus;
  }
  setNominationSideBarStatus(value: boolean) {
    this.nominationSideBarStatus = value;
  }
  setFirstTimeUser(value: boolean) {
    this.firstTimeUser = value;
  }
  getFirstTimeUser(): boolean {
    return this.firstTimeUser;
  }
  setIsEventDataFound(value: boolean): void {
    this.iseventDataFound = value;
  }
  getNomineeData(): string {
    return this.nomineeData;
  }
  setNomineeData(value: any): void {
    this.nomineeData = value;
  }
  setEditNomainateData(value: any) {
    this.draftEditNominateData = value;
  }
  getEditNomainateData() {
    return this.draftEditNominateData;
  }

  public setDateFormRange(min: string, max: string): void {
    this.formMinDate = min;
    this.formMaxDate = max;
  }

  public getFormMaxDate(): string {
    return this.formMaxDate;
  }

  public getFormMinDate(): string {
    return this.formMinDate;
  }

  getIsEventDataFound(): boolean {
    return this.iseventDataFound;
  }
  getleaderboardStatus(): boolean {
    return this.isLeaderboardOpen;
  }
  setLeaderboardStatus(value: boolean): void {
    this.isLeaderboardOpen = value;
  }
  setAdminSideNavOpened(value: boolean): void {
    this.isAdminSideNavOpened = value;
  }
  getAdminSideNavOpened(): boolean {
    return this.isAdminSideNavOpened;
  }
  setAdminPortalOpened(value: boolean): void {
    this.isAdminPortalOpened = value;
  }
  getAdminPortalOpened(): boolean {
    return this.isAdminPortalOpened;
  }
  setJoyRideStepName(value: string): void {
    this.joyRideStepName = value;
  }
  getJoyRideStepName(): string {
    return this.joyRideStepName;
  }
  setWelcomeGifClosed(value: boolean): void {
    this.welcomeGifClosed = value;
  }
  getWelcomeGifClosed(): boolean {
    return this.welcomeGifClosed;
  }
  func = new Promise((resolve, reject) => {
    resolve('Hello');
  });
  sendUpdate(message: string): void {
    this.activityUpdate.next({ text: message });
  }
  sendEventUpdate(message: string): void {
    this.eventUpdate.next({ text: message });
  }
  getEventUpdate(): Observable<Text> {
    return this.eventUpdate.asObservable();
  }
  getUpdate(): Observable<Text> {
    return this.activityUpdate.asObservable();
  }
  setSelectedTabValue(value: NumberInput): void {
    this.selectedTabValue = value;
  }
  getSelectedTabValue(): NumberInput {
    return this.selectedTabValue;
  }
  getCurrentQuarter() {
    const today = new Date();
    const month = today.getMonth() + 1;
    let quarter = Math.floor(month / 3);

    if (month % 3 === 0) {
      quarter--;
    }
    quarter--;
    let prevYear = today.getFullYear() - 1;
    if (quarter === -1) {
      this.previousQuarter = 'Q3-' + prevYear;
    }
    if (quarter === 0) {
      this.previousQuarter = 'Q4-' + prevYear;
    }
    if (quarter === 1) {
      this.previousQuarter = 'Q1-' + today.getFullYear();
    }
    if (quarter === 2) {
      this.previousQuarter = 'Q2-' + today.getFullYear();
    }
    return this.previousQuarter;
  }

  /**
   * returning the response quater filter selection parameters
   * @param minDate mindate to be send to api
   * @param maxDate maxdate to be send to api
   * @param displayedMinDate mindate to be displayed on filter input
   * @param displayedMaxDate maxdate to be displayed on filter input
   * @param selectedStartYear starting financial year to be selected on start year dropdown
   * @param startedYearSelectedQuarter starting quater number selected
   * @param selectedEndYear ending financial year to be selected on end year dropdown
   * @param endingYearSelectedQuarter ending year quater selected
   * @param quarterNo muti quarter number list to be selected in case selectedStartYear === selectedEndYear
   * @returns quater filter selection parameters response
   */
  public getQuaterSelectionResponse(
    minDate: string,
    maxDate: string,
    displayedMinDate: string,
    displayedMaxDate: string,
    selectedStartYear: number,
    startedYearSelectedQuarter: number,
    selectedEndYear: number,
    endingYearSelectedQuarter: number,
    quarterNo: number[]
  ): QuarterSelectionModalResponse {
    let quarterSelectionResponse = {
      minDate: minDate,
      maxDate: maxDate,
      displayedMinDate: displayedMinDate,
      displayedMaxDate: displayedMaxDate,
      quarterNo: quarterNo,
      selectedStartYear: selectedStartYear,
      startedYearSelectedQuarter: startedYearSelectedQuarter,
      selectedEndYear: selectedEndYear,
      endingYearSelectedQuarter: endingYearSelectedQuarter,
    } as QuarterSelectionModalResponse;
    return quarterSelectionResponse;
  }

  /**
   * checking current quater of organization in EC/DC portal
   * fetching and returning response for its previous quater as default
   * @param resetDateRange whether to check sessionstorage data or not
   * @returns quater filter selection parameters response
   */
  public getDefaultDateRangeResponseForEcDcFlow(
    resetDateRange?: boolean
  ): QuarterSelectionModalResponse {
    let quarterSelectionResponse = {} as QuarterSelectionModalResponse;
    if (sessionStorage.getItem('dateRange') && !resetDateRange) {
      let dateRange = JSON.parse(sessionStorage.getItem('dateRange')!);
      quarterSelectionResponse = this.getQuaterSelectionResponse(
        dateRange?.minDate,
        dateRange?.maxDate,
        dateRange?.displayedMinDate,
        dateRange?.displayedMaxDate,
        dateRange?.selectedStartYear,
        dateRange?.startedYearSelectedQuarter,
        dateRange?.selectedEndYear,
        dateRange?.endingYearSelectedQuarter,
        dateRange?.quarterNo
      );
    } else {
      let currentDate = new Date();
      let month = currentDate.getMonth();
      let year = currentDate.getFullYear();
      if (month >= 0 && month <= 2) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year - 1}-01-01`,
          `${year}-03-30`,
          `${year - 2}-10-01`,
          `${year - 1}-12-31`,
          year - 2,
          2,
          year - 1,
          2,
          [0, 1, 2, 3]
        );
      } else if (month >= 3 && month <= 5) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year - 1}-04-01`,
          `${year}-06-30`,
          `${year - 1}-01-01`,
          `${year}-03-31`,
          year - 2,
          3,
          year - 1,
          3,
          [0, 1, 2, 3]
        );
      } else if (month >= 6 && month <= 8) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year - 1}-07-01`,
          `${year}-09-30`,
          `${year - 1}-04-01`,
          `${year}-06-30`,
          year - 1,
          0,
          year,
          0,
          [0, 1, 2, 3]
        );
      } else if (month >= 9 && month <= 11) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year - 1}-10-01`,
          `${year}-12-31`,
          `${year - 1}-07-01`,
          `${year}-09-30`,
          year - 1,
          1,
          year,
          1,
          [0, 1, 2, 3]
        );
      }
    }
    return quarterSelectionResponse;
  }
  /**
   * decode base64 file to image
   * @param base64 Base 64 file
   * @returns
   */
  decodeBase64ToImage(base64: string): string {
    return 'data:image/png;base64,' + base64;
  }
  /**
   * decode base64 file to image
   * @param base64 Base 64 file
   * @returns
   */
  decodeBase64SvgToImage(base64: string): string {
    return 'data:image/svg+xml;base64,' + base64;
  }
  decodeBase64toPdf(base64: string) {
    return 'data:application/pdf;base64,' + base64;
  }

  /**
   * checking current quater of organization in Hr portal
   * fetching and returning response for its previous quater as default
   * @param isHrDashboard whether the current screen is hr dashboard or not
   * @param isHrHistorical whether the current screen is hr historical or not
   * @returns quater filter selection parameters response
   */
  public getDefaultDateRangeResponseForHrFlow(
    isHrDashboard: boolean,
    isHrHistorical: boolean
  ): QuarterSelectionModalResponse {
    let dateRange: QuarterSelectionModalResponse | null = null;
    if (sessionStorage.getItem('hrDashboardDateRange') && isHrDashboard) {
      dateRange = JSON.parse(
        sessionStorage.getItem('hrDashboardDateRange')!
      ) as QuarterSelectionModalResponse;
    } else if (
      sessionStorage.getItem('hrHistoricalDateRange') &&
      isHrHistorical
    ) {
      dateRange = JSON.parse(
        sessionStorage.getItem('hrHistoricalDateRange')!
      ) as QuarterSelectionModalResponse;
    }

    let quarterSelectionResponse = {} as QuarterSelectionModalResponse;
    if (dateRange) {
      quarterSelectionResponse = this.getQuaterSelectionResponse(
        dateRange?.minDate,
        dateRange?.maxDate,
        dateRange?.displayedMinDate,
        dateRange?.displayedMaxDate,
        dateRange?.selectedStartYear,
        dateRange?.startedYearSelectedQuarter,
        dateRange?.selectedEndYear,
        dateRange?.endingYearSelectedQuarter,
        dateRange?.quarterNo
      );
    } else {
      let currentDate = new Date();
      let month = currentDate.getMonth();
      let year = currentDate.getFullYear();
      if (month >= 0 && month <= 2) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year}-01-01`,
          `${year}-03-31`,
          `${year - 1}-10-01`,
          `${year - 1}-12-31`,
          year - 1,
          2,
          year - 1,
          2,
          [2]
        );
      } else if (month >= 3 && month <= 5) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year}-04-01`,
          `${year}-06-30`,
          `${year}-01-01`,
          `${year}-03-31`,
          year - 1,
          3,
          year - 1,
          3,
          [3]
        );
      } else if (month >= 6 && month <= 8) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year}-07-01`,
          `${year}-09-30`,
          `${year}-04-01`,
          `${year}-06-30`,
          year,
          0,
          year,
          0,
          [0]
        );
      } else if (month >= 9 && month <= 11) {
        quarterSelectionResponse = this.getQuaterSelectionResponse(
          `${year}-10-01`,
          `${year}-12-31`,
          `${year}-07-01`,
          `${year}-09-30`,
          year,
          1,
          year,
          1,
          [1]
        );
      }
    }
    return quarterSelectionResponse;
  }

  createEvent(
    resultForm: any,
    empEmail: String,
    flag: String,
    id?: number,
    dynamic?: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const form = new FormData();
      if (resultForm.firstForm.value.bannerChange == true)
        form.append('carousel', resultForm.banner);
      let eventReqBody: any;
      if (flag == 'ADD') {
        if (resultForm.secondForm.value.type === 'Contest') {
          eventReqBody = {
            adminEmail: empEmail,
            eventContributionCategory: resultForm.secondForm.value.categoryIds,
            description: resultForm.firstForm.value.event_desc,
            eventType: resultForm.secondForm.value.type,
            endDate: resultForm.secondForm.value.end_date,
            startDate: resultForm.secondForm.value.start_date,
            rewards: resultForm.secondForm.value.reward_desc,
            targetPoints: resultForm.thirdForm.value.targetpoint.toString(),
            status: 'PENDING',
            summary: resultForm.firstForm.value.event_name,
            enrolled: resultForm.secondForm.value.enrolled,
            colorCode: resultForm.firstForm.value.colorCode,
            freezePointsAfterEventStart:
              resultForm.thirdForm.value.pointsFrezzed,
          };
        } else {
          eventReqBody = {
            adminEmail: empEmail,
            description: resultForm.firstForm.value.event_desc,
            eventType: resultForm.secondForm.value.type,
            endDate: resultForm.secondForm.value.end_date,
            startDate: resultForm.secondForm.value.start_date,
            rewards: resultForm.secondForm.value.reward_desc,
            status: 'PENDING',
            summary: resultForm.firstForm.value.event_name,
            enrolled: resultForm.secondForm.value.enrolled,
            colorCode: resultForm.firstForm.value.colorCode,
            uploadEntry: resultForm.secondForm.value.uploadEntry,
            voters: resultForm.voterForm.value.enrolledVoter,
          };
        }
      } else if (flag == 'EDIT') {
        if (resultForm.secondForm.value.type === 'Contest') {
          eventReqBody = {
            id: id,
            adminEmail: empEmail,
            eventContributionCategory: resultForm.secondForm.value.categoryIds,
            description: resultForm.firstForm.value.event_desc,
            eventType: resultForm.secondForm.value.type,
            endDate: resultForm.secondForm.value.end_date,
            startDate: resultForm.secondForm.value.start_date,
            rewards: resultForm.secondForm.value.reward_desc,
            targetPoints: resultForm.thirdForm.value.targetpoint.toString(),
            status: 'PENDING',
            summary: resultForm.firstForm.value.event_name,
            enrolled: resultForm.secondForm.value.enrolled,
            colorCode: resultForm.firstForm.value.colorCode,
            freezePointsAfterEventStart:
              resultForm.thirdForm.value.pointsFrezzed,
          };
        } else {
          eventReqBody = {
            id: id,
            adminEmail: empEmail,
            description: resultForm.firstForm.value.event_desc,
            eventType: resultForm.secondForm.value.type,
            endDate: resultForm.secondForm.value.end_date,
            startDate: resultForm.secondForm.value.start_date,
            rewards: resultForm.secondForm.value.reward_desc,
            status: 'PENDING',
            summary: resultForm.firstForm.value.event_name,
            enrolled: resultForm.secondForm.value.enrolled,
            colorCode: resultForm.firstForm.value.colorCode,
            uploadEntry: resultForm.secondForm.value.uploadEntry,

            voters: resultForm.voterForm.value.enrolledVoter,
          };
        }
      }
      let con = JSON.stringify(eventReqBody);
      form.append('eventDetails', con);
      if (resultForm.firstForm.value.listingChange == true)
        form.append('banner', resultForm.listing);
      if (
        resultForm.flag == 'ADD' &&
        resultForm.secondForm.value.type === 'Contest'
      ) {
        const dialogRefWarning = this.dialog.open(CommonModalComponent, {
          width: '513px',
          data: {
            title:
              'Contribution points will be freezed once a participant enrolls successfully into the event.',
            button1: 'CONTINUE',
            button2: 'DISCARD',
          },
        });
        dialogRefWarning.afterClosed().subscribe((result) => {
          if (result.res == true) {
            if (dynamic == 'DYNAMIC') {
              this.eventService.createEventDynamic(form).subscribe(
                (data) => {
                  if (data.data) {
                    resolve();
                    this.sendEventUpdate('Event created successfully!');
                    this.dataDialog = {
                      title: 'Dynamically added successfully!',
                    };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '513px',
                        data: this.dataDialog,
                      }
                    );
                  } else {
                    reject();
                    this.toastr.error('Error in adding event draft');
                  }
                },
                (err) => {
                  reject();
                  this.toastr.error('Error in adding event draft');
                }
              );
            } else {
              this.eventService.createEvent(form).subscribe(
                (data) => {
                  if (data.data) {
                    resolve();
                    this.sendEventUpdate('Event created successfully!');
                    this.dataDialog = { title: 'Event created successfully!' };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '513px',
                        data: this.dataDialog,
                      }
                    );
                  } else {
                    reject();
                    this.toastr.error('Error in adding event draft');
                  }
                },
                (err) => {
                  reject();
                  this.toastr.error('Error in adding event draft');
                }
              );
            }
          }
        });
      } else if (
        resultForm.flag == 'ADD' &&
        (resultForm.secondForm.value.type === 'Non Contest' ||
          resultForm.secondForm.value.type === 'Polling Event')
      ) {
        if (dynamic == 'DYNAMIC') {
          this.eventService.createEventDynamic(form).subscribe(
            (data) => {
              if (data.data) {
                resolve();
                this.sendEventUpdate('Event created successfully!');
                this.dataDialog = { title: 'Dynamically added successfully!' };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.dataDialog,
                });
              } else {
                reject();
                this.toastr.error('Error in adding event draft');
              }
            },
            (err) => {
              reject();
              this.toastr.error('Error in adding event draft');
            }
          );
        } else {
          this.eventService.createEvent(form).subscribe(
            (data) => {
              if (data.data) {
                resolve();
                this.sendEventUpdate('Event created successfully!');
                this.dataDialog = { title: 'Event created successfully!' };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.dataDialog,
                });
              } else {
                reject();
                this.toastr.error('Error in adding event draft');
              }
            },
            (err) => {
              reject();
              this.toastr.error('Error in adding event draft');
            }
          );
        }
      } else if (resultForm.flag == 'SAVEASDRAFT') {
        this.eventService.SaveAsDraftEvent(form).subscribe(
          (data) => {
            if (data.data) {
              resolve();
              this.sendEventUpdate('Event saved as draft successfully!');
              this.dataDialog = { title: 'Event saved as draft successfully!' };
              const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                width: '513px',
                data: this.dataDialog,
              });
            } else {
              reject();
              this.toastr.error('Error in saving event draft');
            }
          },
          (err) => {
            reject();
            this.toastr.error('Error in saving event draft');
          }
        );
      }
    });
  }

  uploadEventEntry(
    result: any,
    empEmail: any,
    eventId: any,
    flag: string,
    id?: number,
    eventType?: string,
    uploadCount?: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const form = new FormData();
      const formResponse = result.resultForm;
      if (formResponse.imageChange == true)
        form.append('entryFile', result.uploadFile);
      let entryReqBody: any;
      if (flag == 'EDIT') {
        entryReqBody = {
          summary: formResponse.title,
          description: formResponse.description,
          id: id,
        };
      } else if (flag == 'ADD') {
        entryReqBody = {
          summary: formResponse.title,
          description: formResponse.description,
        };
      }
      let entryWrapper = JSON.stringify(entryReqBody);
      form.append('eventId', eventId);
      form.append('employeeEmail', empEmail);
      form.append('entryDetails', entryWrapper);
      if (result.flag == 'ADD' && eventType != 'Polling Event') {
        const dialogRefWarning = this.dialog.open(CommonModalComponent, {
          width: '513px',
          data: {
            title:
              'This entry will get submitted. Would you like to continue uploading?',
            button1: 'UPLOAD',
            button2: 'CANCEL',
          },
        });
        dialogRefWarning.afterClosed().subscribe((result) => {
          if (result.res == true) {
            this.eventService.uploadEntry(form).subscribe(
              (data) => {
                if (data.data) {
                  resolve();
                  this.sendEventUpdate('Entry submitted successfully!');
                  this.dataDialog = { title: 'Entry submitted successfully!' };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.dataDialog,
                    }
                  );
                } else {
                  reject();
                  this.toastr.error('Error in adding event entry');
                }
              },
              (err) => {
                reject();
                this.toastr.error('Error in adding event entry');
              }
            );
          }
        });
      } else if (flag == 'ADD' && eventType == 'Polling Event') {
        const dialogRefWarning = this.dialog.open(CommonModalComponent, {
          width: '513px',
          data: {
            title:
              'This entry will get submitted. Would you like to continue uploading?',
            button1: 'UPLOAD',
            button2: 'CANCEL',
          },
        });
        dialogRefWarning.afterClosed().subscribe((result) => {
          if (result.res == true) {
            this.eventService.uploadEntry(form).subscribe(
              (data) => {
                if (data.data) {
                  resolve();
                  this.sendEventUpdate('Entry submitted successfully!');
                  this.dataDialog = { title: 'Entry submitted successfully!' };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.dataDialog,
                    }
                  );
                } else {
                  reject();
                  this.toastr.error('Error in adding event entry');
                }
              },
              (err) => {
                reject();
                this.toastr.error('Error in adding event entry');
              }
            );
          }
        });
      } else if (flag == 'EDIT' && eventType == 'Polling Event') {
        const dialogRefWarning = this.dialog.open(CommonModalComponent, {
          width: '550px',
          data: {
            title:
              'This entry will be saved as final entry for now. In future if you update this entry, then the rating of your previous entry will be wiped off.Would you like to continue uploading?',
            button1: 'UPLOAD',
            button2: 'CANCEL',
            countTitle: 'Note : You can only update your entry twice.',
            countTitle1:
              '(' + uploadCount + ' chance left after submitting this entry)',
          },
        });
        dialogRefWarning.afterClosed().subscribe((result) => {
          if (result.res == true) {
            this.eventService.uploadEntry(form).subscribe(
              (data) => {
                if (data.data) {
                  resolve();
                  this.sendEventUpdate('Entry submitted successfully!');
                  this.dataDialog = { title: 'Entry submitted successfully!' };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.dataDialog,
                    }
                  );
                } else {
                  reject();
                  this.toastr.error('Error in adding event entry');
                }
              },
              (err) => {
                reject();
                this.toastr.error('Error in adding event entry');
              }
            );
          }
        });
      }
    });
  }

  createSelfDevCommon(result: any, empEmail?: String): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.selfDevelopment;
    return new Promise<void>((resolve, reject) => {
      const formResponse: self_dev_data = result.resultForm;
      const formData = new FormData();
      let createTrainingReqBody = {
        summary: formResponse.summary,
        description: formResponse.description,
        ecdc: formResponse.ecdc,
        employeeEmail: { email: empEmail },
        selfDevelopment: { duration: formResponse.duration },
        contributionDate: formResponse.date,
      };
      let con = JSON.stringify(createTrainingReqBody);
      if (result.flag == 'newAdd') {
        this.selfDevService.createSelfDev(con).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Self Development Activity Added');
              if (this.totalCount >= 12) {
                this.genericDailogData = {
                  title:
                    'Self Development Activity added successfully. You have added more than 12 self developments. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Self Development Activity added successfully!',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving Self Development Activity');
            }
          },
          (err) => {
            reject();
            if (err.status == 406) {
              this.toastr.error(err.error);
            } else {
              this.toastr.error('Error in saving Self Development Activity');
            }
          }
        );
      } else if (result.flag == 'newAddDraft') {
        this.selfDevService.saveAsDraft(con).subscribe(
          (data) => {
            if (data.data) {
              if (this.totalCount >= 12) {
                this.genericDailogData = {
                  title:
                    'Self Development Activity draft saved successfully. You have added more than 12 self developments. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Self Development Activity draft saved successfully!',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving Self Development Activity');
            }
          },
          (err) => {
            reject();
            this.toastr.error('Error in saving Self Development Activity');
          }
        );
      }
    });
  }
  createSelfDevEdit(
    result: any,
    contributionId?: number,
    empEmail?: String
  ): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.selfDevelopment;
    return new Promise<void>((resolve, reject) => {
      if (result) {
        const formResponse: self_dev_data = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        const formData = new FormData();
        let createTrainingReqBody = {
          summary: formResponse.summary,
          description: formResponse.description,
          employeeEmail: { email: empEmail },
          id: contributionId,
          ecdc: formResponse.ecdc,
          selfDevelopment: { duration: formResponse.duration },
          contributionDate: formResponse.date,
        };
        let con = JSON.stringify(createTrainingReqBody);

        if (result.flag == 'editedAdd') {
          this.selfDevService.createSelfDev(con).subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Self Development Activity Added');
                if (this.totalCount >= 12) {
                  this.genericDailogData = {
                    title:
                      'Self Development Activity added successfully. You have added more than 12 self development. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Self Development Activity added successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in adding Self Development Activity');
              }
            },
            (err) => {
              reject();
              if (err.status == 406) {
                this.toastr.error(err.error);
              } else {
                this.toastr.error('Error in adding Self Development Activity');
              }
            }
          );
        } else if (result.flag == 'editedDraft') {
          this.selfDevService.saveAsDraft(con).subscribe(
            (data) => {
              if (data.data) {
                if (this.totalCount >= 12) {
                  this.genericDailogData = {
                    title:
                      'Self Development Activity draft saved successfully. You have added more than 12 self development. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title:
                      'Self Development Activity draft saved successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error(
                  'Self Development Activity in saving project'
                );
              }
            },
            (err) => {
              reject();
              this.toastr.error('Self Development Activity in saving project');
            }
          );
        }
      }
    });
  }
  createProjectCommon(result: any, empEmail?: String): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.projectCount;
    return new Promise<void>((resolve, reject) => {
      const formResponse: project_data = result.resultForm;
      const formData = new FormData();
      let createTrainingReqBody = {
        summary: formResponse.projectName,
        description: formResponse.description,
        contributionDate: formResponse.date,
        ecdc: formResponse.ecdc,
        project: {
          duration: formResponse.duration,
          projectType: formResponse.projectType,
          clientName: formResponse.clientName,
        },
        employeeEmail: { email: empEmail },
      };
      let con = JSON.stringify(createTrainingReqBody);
      if (result.flag == 'newAdd') {
        this.projectService.createProject(con).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('ProjectAdded');
              if (this.totalCount >= 6) {
                this.genericDailogData = {
                  title:
                    'Project added successfully. You have added more than 6 projects. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Project added successfully!',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving project');
            }
          },
          (err) => {
            reject();
            if (err.status == 406) {
              this.toastr.error(err.error);
            } else {
              this.toastr.error('Error in saving project');
            }
          }
        );
      } else if (result.flag == 'newAddDraft') {
        this.projectService.saveAsDraft(con).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Project Draft Added');
              if (this.totalCount >= 6) {
                this.genericDailogData = {
                  title:
                    'Project draft saved successfully. You have added more than 6 projects. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Project draft saved successfully!',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving project');
            }
          },
          (err) => {
            reject();
            this.toastr.error('Error in saving project');
          }
        );
      }
    });
  }
  createProjectEdit(
    result: any,
    contributionId?: number,
    empEmail?: String
  ): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.projectCount;
    return new Promise<void>((resolve, reject) => {
      if (result) {
        const formResponse: project_data = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        let createTrainingReqBody = {
          summary: formResponse.projectName,
          description: formResponse.description,
          contributionDate: formResponse.date,
          ecdc: formResponse.ecdc,
          project: {
            duration: formResponse.duration,
            projectType: formResponse.projectType,
            clientName: formResponse.clientName,
          },
          employeeEmail: { email: empEmail },
          id: contributionId,
        };
        let con = JSON.stringify(createTrainingReqBody);

        if (result.flag == 'editedAdd') {
          this.projectService.createProject(con).subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Project Added');
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Project added successfully. You have added more than 6 projects. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Project added successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in adding project');
              }
            },
            (err) => {
              if (err.status == 406) {
                this.toastr.error(err.error);
              } else {
                this.toastr.error('Error in adding project');
              }
            }
          );
        } else if (result.flag == 'editedDraft') {
          this.projectService.saveAsDraft(con).subscribe(
            (data) => {
              if (data.data) {
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Project draft saved successfully. You have added more than 6 projects. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Project draft saved successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving project');
              }
            },
            (err) => {
              reject();
              this.toastr.error('Error in saving project');
            }
          );
        }
      }
    });
  }
  createTrainingCommon(result: any, empEmail?: String): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.trainingCount;
    return new Promise<void>((resolve, reject) => {
      const formResponse: session_data = result.resultForm;
      const formData = new FormData();
      let createTrainingReqBody = {
        count: formResponse.count,
        summary: formResponse.summary,
        ecdc: formResponse.ecdc,
        description: formResponse.description,
        trainingAndSession: {
          headcount: formResponse.activityCount,
          otherTechnology: formResponse.other,
          startDate: formResponse.startDate,
          endDate: formResponse.endDate,
          technology: formResponse.technology,
        },
        employeeEmail: { email: empEmail },
      };
      let con = JSON.stringify(createTrainingReqBody);
      if (result.flag == 'newAdd') {
        this.trainingService.createTraining(con).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('TrainingAdded');
              this.totalCount += result.resultForm.count;
              if (this.totalCount >= 15) {
                this.genericDailogData = {
                  title:
                    'Training added successfully.You have added more than 15 training & sessions. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Training added successfully!',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving training');
            }
          },
          (err) => {
            reject();
            if (err.status == 406) {
              this.toastr.error(err.error);
            } else {
              this.toastr.error('Error in saving training');
            }
          }
        );
      } else if (result.flag == 'newAddDraft') {
        this.trainingService.saveAsDraft(con).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Training Draft Added');
              this.totalCount += result.resultForm.count;
              if (this.totalCount >= 15) {
                this.genericDailogData = {
                  title:
                    'Training draft saved successfully.You have added more than 15 training & sessions. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Training draft saved successfully!',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving training');
            }
          },
          (err) => {
            reject();
            this.toastr.error('Error in saving training');
          }
        );
      }
    });
  }
  createTrainingEdit(
    result: any,
    contributionId?: number,
    empEmail?: String
  ): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.trainingCount;
    return new Promise<void>((resolve, reject) => {
      if (result) {
        const formResponse: session_data = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        const formData = new FormData();
        let createTrainingReqBody = {
          count: formResponse.count,
          summary: formResponse.summary,
          description: formResponse.description,
          ecdc: formResponse.ecdc,
          trainingAndSession: {
            headcount: formResponse.activityCount,
            technology: formResponse.technology,
            startDate: formResponse.startDate,
            endDate: formResponse.endDate,
            otherTechnology: formResponse.other,
          },
          employeeEmail: { email: empEmail },
          id: contributionId,
        };
        let con = JSON.stringify(createTrainingReqBody);

        if (result.flag == 'editedAdd') {
          this.trainingService.createTraining(con).subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Training Added');
                this.totalCount += result.resultForm.count;
                if (this.totalCount >= 15) {
                  this.genericDailogData = {
                    title:
                      'Training added successfully.You have added more than 15 training & sessions. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Training added successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in adding training');
              }
            },
            (err) => {
              reject();
              if (err.status == 406) {
                this.toastr.error(err.error);
              } else {
                this.toastr.error('Error in adding training');
              }
            }
          );
        } else if (result.flag == 'editedDraft') {
          this.trainingService.saveAsDraft(con).subscribe(
            (data) => {
              if (data.data) {
                this.totalCount += result.resultForm.count;
                if (this.totalCount >= 15) {
                  this.genericDailogData = {
                    title:
                      'Training draft saved successfully.You have conducted more than 15 training & sessions. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Training draft saved successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving training');
              }
            },
            (err) => {
              reject();
              this.toastr.error('Error in saving training');
            }
          );
        }
      }
    });
  }

  createNominationCommon = (result: any, id?: number): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      console.log(result);
      const formResponse = result.resultForm;
      const awardResponse = result.awardForm;
      const formData = new FormData();
      let createEmpRecommendationReqBody = {
        praise: formResponse.summary,
        recommendedToName: formResponse.recommendedToName,
        recommendedToEmail: formResponse.empEmail,
        recommendedBy: formResponse.recommendedByEmail,
        typeOfAward: awardResponse.award,
        id: id,
      };

      let con = JSON.stringify(createEmpRecommendationReqBody);
      formData.append('formDetails', con);
      if (result.objFile !== null && result.objFile !== undefined) {
        formData.append('file', result.objFile);
      }
      if (result.flag === 'newAdd') {
        this.nominationService.createNomination(formData).subscribe(
          (data: number) => {
            if (data) {
              this.displayModal('success');
              resolve(true);
            } else {
              reject();
              this.toastr.error('Error in saving Nomination');
            }
          },
          (err) => {
            reject();
            this.errorHandles(err.status, err.error);
          }
        );
      } else if (result.flag === 'newAddDraft') {
        this.nominationService.draftNomination(formData).subscribe(
          (data: any) => {
            if (data) {
              this.displayModal('draft');
              resolve(true);
            } else {
              reject();
              this.toastr.error('Error in Saving Nomination as Draft');
            }
          },
          (err) => {
            reject();
            this.errorHandles(err.status, err.error);
          }
        );
      }
    });
  };

  createDraftedAdded = (result: any): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      if (result) {
        const formData = new FormData();
        let con = JSON.stringify(result);
        formData.append('formDetails', con);
        if (result) {
          this.nominationService.createNomination(formData).subscribe(
            (data: any) => {
              if (data) {
                this.displayModal('success');
                resolve(true);
              } else {
                reject();
                this.toastr.error('Error in Adding Nomination');
              }
            },
            (err) => {
              reject();
              this.errorHandles(err.status, err.error);
            }
          );
        }
      }
    });
  };
  createNominationEdit = (result: any, id: number): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      if (result) {
        const formResponse = result.resultForm;
        const awardResponse = result.awardForm;
        formResponse.ecdc === 'EC' ? '0' : '1';
        const formData = new FormData();
        let createEmpRecommendationReqBody = {
          praise: formResponse.summary,
          recommendedToName: formResponse.recommendedToName,
          recommendedToEmail: formResponse.empEmail,
          recommendedBy: formResponse.recommendedByEmail,
          typeOfAward: awardResponse.award,
          id: id,
        };
        let con = JSON.stringify(createEmpRecommendationReqBody);
        formData.append('formDetails', con);
        if (result.objFile !== null && result.objFile !== undefined) {
          formData.append('file', result.objFile);
        }
        console.log('objfile-->', result.objFile);
        console.log('final-->', con);
        if (result.flag === 'editedAdd') {
          this.nominationService.createNomination(formData).subscribe(
            (data: any) => {
              if (data) {
                this.displayModal('success');
                resolve(true);
              } else {
                reject();
                this.toastr.error('Error in Adding Nomination');
              }
            },
            (err) => {
              reject();
              this.errorHandles(err.status, err.error);
            }
          );
        } else if (result.flag === 'editedDraft') {
          this.nominationService.draftNomination(formData).subscribe(
            (data: any) => {
              if (data) {
                this.displayModal('draft');
                resolve(true);
              } else {
                reject();
                this.toastr.error('Error in Saving Nomination as Draft');
              }
            },
            (err) => {
              reject();
              this.errorHandles(err.status, err.error);
            }
          );
        }
      }
    });
  };
  errorHandle() {
    throw new Error('Method not implemented.');
  }

  /**
   * return the message when error occur while add Nomination
   * @param {Number}statusValue - error status value
   */
  errorHandles(statusValue: number, status: string): void {
    if (statusValue === 505) {
      this.toastr.error('upload only .pdf, .jpeg, .jpg .eml files');
    } else if (statusValue === 406) {
      this.toastr.error(status);
    } else if (statusValue === 202) {
      this.toastr.error('Action taken but not yet not Completed');
    } else {
      this.toastr.error('Error in adding Nomination');
    }
  }

  displayModal = (flag: string): void => {
    let dialogRef: MatDialogRef<ConfirmationModalComponent>;
    flag === 'draft'
      ? (this.genericDailogData = {
          title: 'Nomination draft saved successfully!',
          img: 'assets/images/n_image/Draft.gif',
          heading: 'Draft Saved!',
        })
      : (this.genericDailogData = {
          title: 'Nomination added successfully!',
          img: 'assets/images/n_image/Success.gif',
          heading: 'Success!',
        });
    dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '513px',
      data: this.genericDailogData,
    });
  };

  createCeritificateCommon(result: any, empEmail?: String): Promise<boolean> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.certificationCount;
    return new Promise<boolean>((resolve, reject) => {
      const formResponse = result.resultForm;
      const formData = new FormData();
      let createCertificateReqBody = {
        summary: formResponse.summary,
        employeeEmail: { email: empEmail },
        ecdc: formResponse.ecdc,
        certificate: {
          dateOfCompletion: formResponse.date,
          technology: formResponse.technology,
          otherTechnology: formResponse.other,
          certificationCost: formResponse.paid,
          amount: formResponse.amount,
        },
      };
      let con = JSON.stringify(createCertificateReqBody);
      formData.append('formDetails', con);
      formData.append('file', result.objFile);
      if (result.resultForm.paid === 'Paid') {
        formData.append('invoice', result.invoice);
      }
      if (result.flag == 'newAdd') {
        this.certificateService.createCertificate(formData).subscribe(
          (data) => {
            if (data.data) {
              let dialogRef: MatDialogRef<ConfirmationModalComponent>;
              this.sendUpdate('CertificateAdded');
              if (this.totalCount >= 6) {
                this.genericDailogData = {
                  title:
                    'Certification added successfully. You have added more than 6 certifications. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Certification added successfully!',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }

              dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe({
                  next: () => {
                    resolve(true);
                  },
                });
            } else {
              reject();
              this.toastr.error('Error in saving certificate');
            }
          },
          (err) => {
            reject();
            if (err.status == 505) {
              this.toastr.error('upload only .pdf, .jpeg, .jpg .eml files');
            } else if (err.status == 406) {
              this.toastr.error(err.error);
            } else if (err.status == 202) {
              this.toastr.error('This file already Exists');
            } else {
              this.toastr.error('Error in saving certificate');
            }
          }
        );
      } else if (result.flag == 'newAddDraft') {
        this.certificateService.saveAsDraft(formData).subscribe(
          (data) => {
            let dialogRef: MatDialogRef<ConfirmationModalComponent>;
            if (data.data) {
              this.sendUpdate('Certificate Draft Added');
              if (this.totalCount >= 6) {
                this.genericDailogData = {
                  title:
                    'Certification draft saved successfully. You have added more than 6 certifications. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Certification draft saved successfully!',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe({
                  next: () => {
                    resolve(true);
                  },
                });
            } else {
              reject();
              this.toastr.error('Error in saving draft');
            }
          },
          (err) => {
            reject();
            if (err.status == 505) {
              this.toastr.error('upload only .pdf, .jpeg, .jpg .eml files');
            } else if (err.status == 406) {
              this.toastr.error('This file already Exists');
            } else {
              this.toastr.error('Error in saving draft');
            }
          }
        );
      }
    });
  }
  createcertificateEdit(
    result: any,
    contributionId?: number,
    empEmail?: String
  ): Promise<boolean> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.certificationCount;
    return new Promise<boolean>((resolve, reject) => {
      if (result) {
        const formResponse = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        const formData = new FormData();
        if (formResponse.technology) {
        }
        let createCertificateReqBody = {
          summary: formResponse.summary,
          description: formResponse.description,
          employeeEmail: { email: empEmail },
          ecdc: formResponse.ecdc,
          certificate: {
            dateOfCompletion: formResponse.date,
            technology: formResponse.technology,
            otherTechnology: formResponse.other,
            certificationCost: formResponse.paid,
            amount: formResponse.amount,
          },
          id: contributionId,
        };
        let con = JSON.stringify(createCertificateReqBody);
        formData.append('formDetails', con);
        if (result.checkFile == true) {
          formData.append('file', result.objFile);
        }
        if (result.invoiceCheckFile == true) {
          formData.append('invoice', result.invoice);
        }

        if (result.flag == 'editedAdd') {
          this.certificateService.createCertificate(formData).subscribe(
            (data) => {
              let dialogRef: MatDialogRef<ConfirmationModalComponent>;
              if (data.data) {
                this.sendUpdate('Certificate Added');
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Certification added successfully. You have added more than 6 certifications. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  dialogRef = this.dialog.open(ConfirmationModalComponent, {
                    width: '650px',
                    data: this.genericDailogData,
                  });
                } else {
                  this.genericDailogData = {
                    title: 'Certification added successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  dialogRef = this.dialog.open(ConfirmationModalComponent, {
                    width: '513px',
                    data: this.genericDailogData,
                  });
                }
                dialogRef
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe({
                    next: () => {
                      resolve(true);
                    },
                  });
              } else {
                reject();
                this.toastr.error('Error in adding certificate');
              }
            },
            (err) => {
              reject();
              if (err.status == 505) {
                this.toastr.error('upload only .pdf, .jpeg, .jpg .eml files');
              } else if (err.status == 406) {
                this.toastr.error(err.error);
              } else if (err.status == 202) {
                this.toastr.error('This file already Exists');
              } else {
                this.toastr.error('Error in adding certificate');
              }
            }
          );
        } else if (result.flag == 'editedDraft') {
          this.certificateService.saveAsDraft(formData).subscribe(
            (data) => {
              let dialogRef: MatDialogRef<ConfirmationModalComponent>;
              if (data.data) {
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Certification draft saved successfully. You have added more than 6 certifications. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  dialogRef = this.dialog.open(ConfirmationModalComponent, {
                    width: '650px',
                    data: this.genericDailogData,
                  });
                } else {
                  this.genericDailogData = {
                    title: 'Certification draft saved successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  dialogRef = this.dialog.open(ConfirmationModalComponent, {
                    width: '513px',
                    data: this.genericDailogData,
                  });
                }
                dialogRef
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe({
                    next: () => {
                      resolve(true);
                    },
                  });
              } else {
                reject();
                this.toastr.error('Error in saving draft');
              }
            },
            (err) => {
              reject();
              if (err.status == 505) {
                this.toastr.error('upload only .pdf, .jpeg, .jpg .eml files');
              } else if (err.status == 202) {
                this.toastr.error('This file already Exists');
              } else {
                this.toastr.error('Error in saving draft');
              }
            }
          );
        }
      }
    });
  }
  createClientCommon(result: any, empEmail: String): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.feedbackCount;
    return new Promise<void>((resolve, reject) => {
      const formResponse: feedback_data = result.resultForm;
      const formData = new FormData();
      let createClientReqBody = {
        summary: formResponse.summary,
        description: formResponse.description,
        employeeEmail: { email: empEmail },
        ecdc: formResponse.ecdc,
        clientFeedback: {
          projectName: formResponse.projectName,
          clientName: formResponse.clientName,
        },
      };
      let con = JSON.stringify(createClientReqBody);
      formData.append('formDetails', con);
      if (result.objFile !== null && result.objFile !== undefined) {
        formData.append('file', result.objFile);
      }
      if (result.flag == 'newAdd') {
        this.feedbackService.createFeedback(formData).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Client Feedback Added');
              if (this.totalCount >= 6) {
                this.genericDailogData = {
                  title:
                    'Client feedback saved successfully. You have added more than 6 client feedback. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Client feedback saved successfully!',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }

              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving client feedback');
            }
          },
          (err) => {
            reject();
            if (err.status == 505) {
              this.toastr.error('upload only .png, .jpeg, .jpg .eml files');
            } else if (err.status == 406) {
              this.toastr.error(err.error);
            } else {
              this.toastr.error('Error in saving client feedback');
            }
          }
        );
      } else if (result.flag == 'newAddDraft') {
        this.feedbackService.saveAsDraft(formData).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Client Feedback Draft Added');
              if (this.totalCount >= 6) {
                this.genericDailogData = {
                  title:
                    'Client feedback draft saved successfully. You have added more than 6 client feedback. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Client feedback draft saved successfully!',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving draft');
            }
          },
          (err) => {
            reject();
            if (err.status == 505) {
              this.toastr.error('upload only .png, .jpeg, .jpg .eml files');
            } else {
              this.toastr.error('Error in saving draft');
            }
          }
        );
      }
    });
  }
  createClientEdit(
    result: any,
    contributionId?: number,
    empEmail?: String
  ): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.feedbackCount;
    return new Promise<void>((resolve, reject) => {
      if (result) {
        const formResponse: feedback_data = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        const formData = new FormData();
        let createClientReqBody = {
          summary: formResponse.summary,
          description: formResponse.description,
          employeeEmail: { email: empEmail },
          ecdc: formResponse.ecdc,
          clientFeedback: {
            projectName: formResponse.projectName,
            clientName: formResponse.clientName,
          },
          id: contributionId,
        };
        let con = JSON.stringify(createClientReqBody);
        formData.append('formDetails', con);
        if (result.checkFile == true) {
          if (result.objFile !== null && result.objFile !== undefined) {
            formData.append('file', result.objFile);
          }
        }
        if (result.flag == 'editedAdd') {
          this.feedbackService.createFeedback(formData).subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Client Feedback Added');
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Client feedback saved successfully. You have added more than 6 client feedback. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Client feedback saved successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in adding client feedback');
              }
            },
            (err) => {
              reject();
              if (err.status == 505) {
                this.toastr.error('upload only .png, .jpeg, .jpg .eml files');
              } else if (err.status == 406) {
                this.toastr.error(err.error);
              } else {
                this.toastr.error('Error in adding client feedback');
              }
            }
          );
        } else if (result.flag == 'editedDraft') {
          this.feedbackService.saveAsDraft(formData).subscribe(
            (data) => {
              if (data.data) {
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Client feedback draft saved successfully. You have added more than 6 client feedback. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Client feedback draft saved successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving draft');
              }
            },
            (err) => {
              reject();
              if (err.status == 505) {
                this.toastr.error('upload only .png, .jpeg, .jpg .eml files');
              } else {
                this.toastr.error('Error in saving draft');
              }
            }
          );
        }
      }
    });
  }

  reportNewBug(result: any, empEmail?: String): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const formResponse: bug_data = result.resultForm;
      const formData = new FormData();
      if (formResponse.feature === 'Others') {
        formResponse.feature = formResponse.otherFeature;
      }
      if (formResponse.issue === 'Other') {
        formResponse.issue = formResponse.otherIssue;
      }
      let createBugReqBody = {
        category: formResponse.issue,
        websiteFeature: formResponse.feature,
        feedbackDescription: formResponse.description,
        feedbackTitle: formResponse.bugTitle,
        reportedByEmail: empEmail,
      };
      let con = JSON.stringify(createBugReqBody);
      formData.append('feedbackDetails', con);
      formData.append('feedbackFile', result.uploadFile);
      if (result.flag == 'ADD') {
        this.bugService.reportBug(formData).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Bug Added');
              this.genericDailogData = {
                title:
                  'Feedback published successfully! Contripoint Team will reach out to you shortly.',
                img: '../../../../assets/images/n_image/Success.gif',
                heading: 'Success!',
              };
              const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                width: '650px',
                data: this.genericDailogData,
              });
              resolve();
            } else {
              reject();
              this.toastr.error('Error in Reporting Bug');
            }
          },
          (err) => {
            reject();
            if (err.status == 505) {
              this.toastr.error('upload only .jpeg, .jpg , .png files');
            } else {
              this.toastr.error('Error in  Reporting Bug');
            }
          }
        );
      }
    });
  }

  createInterviewCommon(result: any, empEmail?: String): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.interviewCount;

    return new Promise<void>((resolve, reject) => {
      const formResponse: interview_data = result.resultForm;
      let createInterviewReqBody = {
        employeeEmail: { email: empEmail },
        count: formResponse.count,
        ecdc: formResponse.ecdc,
        description: formResponse.description,
        interview: {
          month: formResponse.month,
          experienceLevel: formResponse.experienceLevel,
          profile: formResponse.employeeProfile,
        },
      };

      if (result.flag == 'newAdd') {
        this.interviewService
          .createInterviewTaken(createInterviewReqBody)
          .subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('InterviewAdded');
                this.totalCount += result.resultForm.count;
                if (this.totalCount >= 50) {
                  this.genericDailogData = {
                    title:
                      'Interview Taken saved successfully. You have taken more than 50 interviews .Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Interview Taken saved successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving interview taken');
              }
            },
            (err) => {
              reject();
              if (err.status == 406) {
                this.toastr.error(err.error);
              } else {
                this.toastr.error('Error in saving interview taken');
              }
            }
          );
      } else if (result.flag == 'newAddDraft') {
        this.interviewService.saveAsDraft(createInterviewReqBody).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Interview Draft Added');
              this.totalCount += result.resultForm.count;
              if (this.totalCount >= 50) {
                this.genericDailogData = {
                  title:
                    'Interview Taken draft saved successfully. You have taken more than 50 interviews .Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Interview Taken  draft saved successfully!',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving draft');
            }
          },
          (err) => {
            reject();
            this.toastr.error('Error in saving draft');
          }
        );
      }
    });
  }
  createInterviewEdit(
    result: any,
    contributionId?: any,
    empEmail?: String
  ): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.interviewCount;
    return new Promise<void>((resolve, reject) => {
      if (result) {
        const formResponse: interview_data = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        let createInterviewReqBody = {
          employeeEmail: { email: empEmail },
          count: formResponse.count,
          description: formResponse.description,
          ecdc: formResponse.ecdc,
          interview: {
            month: formResponse.month,
            experienceLevel: formResponse.experienceLevel,
            profile: formResponse.employeeProfile,
          },
          id: contributionId,
        };
        if (result.flag == 'editedAdd') {
          this.interviewService
            .createInterviewTaken(createInterviewReqBody)
            .subscribe(
              (data) => {
                if (data.data) {
                  this.sendUpdate('Interview Added');
                  this.totalCount += result.resultForm.count;
                  if (this.totalCount >= 50) {
                    this.dataDialog = {
                      title:
                        'Interview Taken saved successfully. You have taken more than 50 interviews .Contripoint team might catch up with you for the verification of the data.',
                    };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '650px',
                        data: this.genericDailogData,
                      }
                    );
                  } else {
                    this.genericDailogData = {
                      title: 'Interview Taken saved successfully!',
                      img: '../../../../assets/images/n_image/Success.gif',
                      heading: 'Success!',
                    };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '513px',
                        data: this.genericDailogData,
                      }
                    );
                  }
                  resolve();
                } else {
                  reject();
                  this.toastr.error('Error in adding interview taken');
                }
              },
              (err) => {
                reject();
                if (err.status == 406) {
                  this.toastr.error(err.error);
                } else {
                  this.toastr.error('Error in adding interview taken');
                }
              }
            );
        } else if (result.flag == 'editedDraft') {
          this.interviewService.saveAsDraft(createInterviewReqBody).subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Interview Draft Added');
                this.totalCount += result.resultForm.count;
                if (this.totalCount >= 50) {
                  this.genericDailogData = {
                    title:
                      'Interview Taken draft saved successfully. You have taken more than 50 interviews .Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Interview Taken draft saved successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving draft');
              }
            },
            (err) => {
              reject();
              this.toastr.error('Error in saving draft');
            }
          );
        }
      }
    });
  }
  createMentorshipCommon(result: any, empEmail: String): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.mentorshipCount;
    return new Promise<void>((resolve, reject) => {
      const formResponse: mentorship_data = result.resultForm;
      let createMentorshipRequestBody = {
        employeeEmail: { email: empEmail },
        relatedEmployee: formResponse.mentored,
        ecdc: formResponse.ecdc,
        description: formResponse.description,
      };
      if (result.flag == 'newAdd') {
        this.mentorshipService
          .createMentorship(createMentorshipRequestBody)
          .subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('MentorshipAdded');
                this.totalCount += result.resultForm.mentored.length;
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Mentorship saved successfully. You have mentored more than 6 colleagues. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Mentorship saved successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving Mentorship');
              }
            },
            (err) => {
              reject();
              if (err.status == 406) {
                this.toastr.error(err.error);
              } else {
                this.toastr.error('Error in saving Mentorship');
              }
            }
          );
      } else if (result.flag == 'newAddDraft') {
        this.mentorshipService
          .saveAsDraft(createMentorshipRequestBody)
          .subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Mentorship Draft Added');
                this.totalCount += result.resultForm.mentored.length;
                if (this.totalCount >= 6) {
                  this.genericDailogData = {
                    title:
                      'Mentorship draft saved successfully. You have mentored more than 6 colleagues. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Mentorship draft saved successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                }

                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving draft');
              }
            },
            (err) => {
              reject();
              this.toastr.error('Error in saving draft');
            }
          );
      }
    });
  }
  createMentorshipEdit(
    result: any,
    contributionId?: number,
    empEmail?: String
  ): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.mentorshipCount;
    return new Promise<void>((resolve, reject) => {
      if (result) {
        const formResponse: mentorship_data = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        let createMentorshipRequestBody = {
          employeeEmail: { email: empEmail },
          relatedEmployee: formResponse.mentored,
          ecdc: formResponse.ecdc,
          description: formResponse.description,
          id: contributionId,
        };
        if (result.flag == 'editedAdd') {
          this.mentorshipService
            .createMentorship(createMentorshipRequestBody)
            .subscribe(
              (data) => {
                if (data.data) {
                  this.sendUpdate('Mentorship Added');
                  this.totalCount += result.resultForm.mentored.length;
                  if (this.totalCount >= 6) {
                    this.genericDailogData = {
                      title:
                        'Mentorship saved successfully. You have mentored more than 6 colleagues. Contripoint team might catch up with you for the verification of the data.',
                      img: '../../../../assets/images/n_image/Success.gif',
                      heading: 'Success!',
                    };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '650px',
                        data: this.genericDailogData,
                      }
                    );
                  } else {
                    this.genericDailogData = {
                      title: 'Mentorship saved successfully!',
                      img: '../../../../assets/images/n_image/Success.gif',
                      heading: 'Success!',
                    };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '650px',
                        data: this.genericDailogData,
                      }
                    );
                  }

                  resolve();
                } else {
                  reject();
                  this.toastr.error('Error in adding Mentorship');
                }
              },
              (err) => {
                reject();
                if (err.status == 406) {
                  this.toastr.error(err.error);
                } else {
                  this.toastr.error('Error in adding Mentorship');
                }
              }
            );
        } else if (result.flag == 'editedDraft') {
          this.mentorshipService
            .saveAsDraft(createMentorshipRequestBody)
            .subscribe(
              (data) => {
                if (data.data) {
                  this.sendUpdate('Mentorship draft Added');
                  this.totalCount += result.resultForm.mentored.length;
                  if (this.totalCount >= 6) {
                    this.genericDailogData = {
                      title:
                        'Mentorship draft saved successfully. You have mentored more than 6 colleagues. Contripoint team might catch up with you for the verification of the data.',
                      img: '../../../../assets/images/n_image/Draft.gif',
                      heading: 'Draft Saved!',
                    };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '650px',
                        data: this.genericDailogData,
                      }
                    );
                  } else {
                    this.genericDailogData = {
                      title: 'Mentorship draft saved successfully!',
                      img: '../../../../assets/images/n_image/Draft.gif',
                      heading: 'Draft Saved!',
                    };
                    const dialogRef = this.dialog.open(
                      ConfirmationModalComponent,
                      {
                        width: '650px',
                        data: this.genericDailogData,
                      }
                    );
                  }

                  resolve();
                } else {
                  reject();
                  this.toastr.error('Error in saving draft');
                }
              },
              (err) => {
                reject();
                this.toastr.error('Error in saving draft');
              }
            );
        }
      }
    });
  }
  createTeamBuildingCommon(result: any, empEmail?: String): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.teamBuildingCount;

    return new Promise<void>((resolve, reject) => {
      const formResponse: team_building_data = result.resultForm;
      let createTeamBuildingReqBody = {
        summary: formResponse.summary,
        description: formResponse.description,
        employeeEmail: { email: empEmail },
        ecdc: formResponse.ecdc,
        teamBuilding: { headcount: formResponse.headCount },
        contributionDate: formResponse.date,
      };
      let con = JSON.stringify(createTeamBuildingReqBody);
      if (result.flag == 'newAdd') {
        this.teamBuildingService.createTeamBuilding(con).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Team Building Activity Added');
              if (this.totalCount >= 12) {
                this.genericDailogData = {
                  title:
                    'Activity added successfully. You have conducted more than 12 Team Building Activity. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Activity added successfully!',
                  img: '../../../../assets/images/n_image/Success.gif',
                  heading: 'Success!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving Team Building Activity');
            }
          },
          (err) => {
            reject();
            if (err.status == 406) {
              this.toastr.error(err.error);
            } else {
              this.toastr.error('Error in saving Team Building Activity');
            }
          }
        );
      } else if (result.flag == 'newAddDraft') {
        this.teamBuildingService.saveAsDraft(con).subscribe(
          (data) => {
            if (data.data) {
              this.sendUpdate('Team Building Activity Draft Added');
              if (this.totalCount >= 12) {
                this.genericDailogData = {
                  title:
                    'Activity save as draft successfully. You have conducted more than 12 Team Building Activity. Contripoint team might catch up with you for the verification of the data.',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '650px',
                  data: this.genericDailogData,
                });
              } else {
                this.genericDailogData = {
                  title: 'Activity save as draft successfully!',
                  img: '../../../../assets/images/n_image/Draft.gif',
                  heading: 'Draft Saved!',
                };
                const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                  width: '513px',
                  data: this.genericDailogData,
                });
              }
              resolve();
            } else {
              reject();
              this.toastr.error('Error in saving draft');
            }
          },
          (err) => {
            reject();
            this.toastr.error('Error in saving draft');
          }
        );
      }
    });
  }
  createTeamBuildingEdit(
    result: any,
    contributionId?: number,
    empEmail?: String
  ): Promise<void> {
    this.currentCount = this.getContributionCount();
    this.totalCount = this.currentCount.teamBuildingCount;
    return new Promise<void>((resolve, reject) => {
      if (result) {
        const formResponse: team_building_data = result.resultForm;
        if (formResponse.ecdc == 'EC') {
          formResponse.ecdc = '0';
        } else if (formResponse.ecdc == 'DC') {
          formResponse.ecdc = '1';
        }
        let createTeamBuildingReqBody = {
          summary: formResponse.summary,
          description: formResponse.description,
          employeeEmail: { email: empEmail },
          ecdc: formResponse.ecdc,
          id: contributionId,
          teamBuilding: { headcount: formResponse.headCount },
          contributionDate: formResponse.date,
        };
        let con = JSON.stringify(createTeamBuildingReqBody);
        if (result.flag == 'editedAdd') {
          this.teamBuildingService.createTeamBuilding(con).subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Team Building Activity Added');
                if (this.totalCount >= 12) {
                  this.genericDailogData = {
                    title:
                      'Activity added successfully. You have conducted more than 12 Team Building Activity. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Activity added successfully!',
                    img: '../../../../assets/images/n_image/Success.gif',
                    heading: 'Success!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in adding Team Building Activity');
              }
            },
            (err) => {
              reject();
              if (err.status == 406) {
                this.toastr.error(err.error);
              } else {
                this.toastr.error('Error in adding Team Building Activity');
              }
            }
          );
        } else if (result.flag == 'editedDraft') {
          this.teamBuildingService.saveAsDraft(con).subscribe(
            (data) => {
              if (data.data) {
                this.sendUpdate('Team Building Activity Draft Added');
                if (this.totalCount >= 12) {
                  this.genericDailogData = {
                    title:
                      'Activity save as draft successfully. You have conducted more than 12 Team Building Activity. Contripoint team might catch up with you for the verification of the data.',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '650px',
                      data: this.genericDailogData,
                    }
                  );
                } else {
                  this.genericDailogData = {
                    title: 'Activity save as draft successfully!',
                    img: '../../../../assets/images/n_image/Draft.gif',
                    heading: 'Draft Saved!',
                  };
                  const dialogRef = this.dialog.open(
                    ConfirmationModalComponent,
                    {
                      width: '513px',
                      data: this.genericDailogData,
                    }
                  );
                }
                resolve();
              } else {
                reject();
                this.toastr.error('Error in saving draft');
              }
            },
            (err) => {
              reject();
              this.toastr.error('Error in saving draft');
            }
          );
        }
      }
    });
  }

   getYearsData() : TimeData {
    let currentMonth = new Date().getMonth() + 1;
     // Initialize with default values
     let fromDate: string = '';
     let toDate: string = '';
   
    let year : any  =  `${moment().year()}`;
    if (currentMonth <= 3) {
      // new Date(year - 1, 0o4, 0o1, 0o0, 0o0, 0o0, 0o1);
      fromDate = `${moment().year() - 1}-04-01 00:00:00.001`;
      toDate = `${moment().year()}-03-31 23:59:59.999`;
    } else {
      fromDate = `${moment().year()}-04-01 00:00:00.001`;
      toDate = `${moment().year() + 1}-03-31 23:59:59.999`;
    }
  return {fromDate ,toDate};
  }
}
