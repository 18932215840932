import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  QuarterSelectionDataDetails,
  QuarterSelectionDetails,
} from "src/app/shared/models/quater-selection.model";

@Component({
  selector: "app-quarter-select-filter-modal",
  templateUrl: "./quarter-select-filter-modal.component.html",
  styleUrls: ["./quarter-select-filter-modal.component.css"],
})
export class QuarterSelectFilterModalComponent implements OnInit {
  public quarterForm: FormGroup;
  public selectedQuarters: number[];
  public startingSelectedQuarters: number;
  public endingSelectedQuarters: number;
  public quarterData: QuarterSelectionDetails[];
  public years: number[];
  public quarterError: string;
  public startDate: string;
  public endDate: string;
  public startDateText: string;
  public endDateText: string;
  public displayedStartDate: string;
  public displayedEndDate: string;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: QuarterSelectionDataDetails,
    private dialog: MatDialogRef<QuarterSelectFilterModalComponent>
  ) {
    this.selectedQuarters = [] as number[];
    this.startingSelectedQuarters = -1;
    this.endingSelectedQuarters = -1;
    this.quarterData = [
      {
        quarter: "1",
        months: ["APR", "MAY", "JUNE"],
      },
      {
        quarter: "2",
        months: ["JULY", "AUG", "SEPT"],
      },
      {
        quarter: "3",
        months: ["OCT", "NOV", "DEC"],
      },
      {
        quarter: "4",
        months: ["JAN", "FEB", "MAR"],
      },
    ] as QuarterSelectionDetails[];
    this.years = [] as number[];
    this.quarterForm = this.formBuilder.group({});
    this.quarterError = "";
    this.startDate = "";
    this.endDate = "";
    this.startDateText = "NA";
    this.endDateText = "NA";
    this.displayedStartDate = "";
    this.displayedEndDate = "";
  }

  ngOnInit(): void {
    this.setYearFilter();
    this.startingSelectedQuarters = this.data?.startedYearSelectedQuarter;
    this.endingSelectedQuarters = this.data?.endingYearSelectedQuarter;
    this.setStartAndEndDate();
  }

  /**
   * to reset all quarters selection on year change
   */
  public resetOnYearChange() {
    if (
      this.quarterForm.value.startingYear > this.quarterForm.value.endingYear
    ) {
      this.quarterForm.controls["endingYear"].setValue(
        this.quarterForm.value.startingYear
      );
      this.quarterForm.updateValueAndValidity();
    }

    this.selectedQuarters = [];
    this.startingSelectedQuarters = -1;
    this.endingSelectedQuarters = -1;
    this.quarterError = "";
    this.setStartAndEndDate();
  }

  /**
   * to set year filter dropdown with values
   */
  private setYearFilter(): void {
    let currentDate = new Date();
    let currentYear = currentDate?.getFullYear();

    if (currentDate?.getMonth() <= 2) {
      currentYear = currentDate?.getFullYear() - 1;
    }
    let startingYear = currentYear - 5;

    for (let i = startingYear; i <= currentYear; i++) {
      this.years.push(i);
    }
    this.selectedQuarters = this.data?.quarterNo;
    this.quarterForm = this.formBuilder.group({
      startingYear: [this.data?.selectedStartYear, Validators.required],
      endingYear: [this.data?.selectedEndYear, Validators.required],
    });
  }

  /**
   * setting the startDate
   * @param startDate actual date to be sent to BE
   * @param displayedStartDate date to be displayed on date input box
   * @param startDateText date to be displayed on modal
   */
  public setStartDate(
    startDate?: string,
    displayedStartDate?: string,
    startDateText?: string
  ): void {
    this.startDate = startDate ? startDate : '' ;
    this.displayedStartDate = displayedStartDate ? displayedStartDate : '';
    this.startDateText = startDateText ? startDateText : 'NA';
  }

  /**
   * setting the endDate
   * @param startDate actual date to be sent to BE
   * @param displayedStartDate date to be displayed on date input box
   * @param startDateText date to be displayed on modal
   */
  public setEndDate(
    endDate?: string,
    displayedEndDate?: string,
    endDateText?: string
  ): void {
    this.endDate = endDate ? endDate : '';
    this.displayedEndDate = displayedEndDate ? displayedEndDate : '';
    this.endDateText = endDateText ? endDateText : 'NA';
  }

  /**
   * to set start & end date and to display it on on UI
   * in case of user selects diff starting and ending financial year
   */
  public setStartAndEndDate() {
    let startingYear = this.quarterForm.value.startingYear;
    let endingYear = this.quarterForm.value.endingYear;
    switch (this.startingSelectedQuarters) {
      case 0: {
        this.setStartDate(
          `${startingYear}-07-01`,
          `${startingYear}-04-01`,
          `1st April, ${startingYear}`
        );
        break;
      }
      case 1: {
        this.setStartDate(
          `${startingYear}-10-01`,
          `${startingYear}-07-01`,
          `1st July, ${startingYear}`
        );
        break;
      }
      case 2: {
        this.setStartDate(
          `${startingYear + 1}-01-01`,
          `${startingYear}-10-01`,
          `1st October, ${startingYear}`
        );
        break;
      }
      case 3: {
        this.setStartDate(
          `${startingYear + 1}-04-01`,
          `${startingYear + 1}-01-01`,
          `1st January, ${startingYear + 1}`
        );
        break;
      }
      default: {
        this.setStartDate();
      }
    }

    switch (this.endingSelectedQuarters) {
      case 0: {
        this.setEndDate(
          `${endingYear}-09-30`,
          `${endingYear}-06-30`,
          `30th June, ${endingYear}`
        );
        break;
      }
      case 1: {
        this.setEndDate(
          `${endingYear}-12-31`,
          `${endingYear}-09-30`,
          `30th September, ${endingYear}`
        );
        break;
      }
      case 2: {
        this.setEndDate(
          `${endingYear + 1}-03-31`,
          `${endingYear}-12-31`,
          `31st December, ${endingYear}`
        );
        break;
      }
      case 3: {
        this.setEndDate(
          `${endingYear + 1}-06-30`,
          `${endingYear + 1}-03-31`,
          `31st March, ${endingYear + 1}`
        );
        break;
      }
      default: {
        this.setEndDate();
      }
    }
  }

  /**
   * to set the value of starting financial year quarter number
   * @param value quarter number
   */
  public onStartingQuaterSelect(value: number): void {
    this.startingSelectedQuarters = value;
    this.checkSameYearQuarterValidation();
    this.setStartAndEndDate();
  }

  /**
   * to set the value of ending financial year quarter number
   * @param value quarter number
   */
  public onEndingQuaterSelect(value: number): void {
    this.endingSelectedQuarters = value;
    this.checkSameYearQuarterValidation();
    this.setStartAndEndDate();
  }

  private checkSameYearQuarterValidation(): void {
    this.quarterError = "";
    if (
      this.quarterForm.value.startingYear === this.quarterForm.value.endingYear
    ) {
      if (!this.selectedQuarters.length) {
        this.quarterError = "Please select atleast 1 quarter";
      } else if (this.selectedQuarters.length > 1) {
        for (let i = 0; i < this.selectedQuarters.length; i++) {
          if (
            this.selectedQuarters[i] === 0 &&
            !this.selectedQuarters.includes(1)
          ) {
            this.quarterError = "Please select consecutive quarters";
            break;
          } else if (
            this.selectedQuarters[i] === 3 &&
            !this.selectedQuarters.includes(2)
          ) {
            this.quarterError = "Please select consecutive quarters";
            break;
          } else {
            if (
              !this.selectedQuarters.includes(this.selectedQuarters[i] - 1) &&
              !this.selectedQuarters.includes(this.selectedQuarters[i] + 1)
            ) {
              this.quarterError = "Please select consecutive quarters";
              break;
            }
          }
        }
      }
    } else {
      if (
        this.startingSelectedQuarters === -1 ||
        this.endingSelectedQuarters === -1
      ) {
        this.quarterError =
          "Please select 1 quarter each from starting and ending financial year";
      } else {
        this.quarterError = "";
      }
    }
  }

  /**
   * to set the selected quarter number
   * @param value quarter number (0-3)
   */
  public onQuaterSelect(value: number): void {
    if (this.selectedQuarters.includes(value)) {
      this.selectedQuarters.splice(this.selectedQuarters.indexOf(value), 1);
    } else {
      this.selectedQuarters.push(value);
    }
    this.selectedQuarters.sort();
    this.quarterError = "";
    this.checkSameYearQuarterValidation();
  }

  /**
   * sending quarter number, selected year min and max date to parent component
   */
  public sendDataToParentComponent(): void {
    this.checkSameYearQuarterValidation();
    if (
      this.quarterForm.value.startingYear === this.quarterForm.value.endingYear
    ) {
      if (!this.quarterError.length) {
        let selectedYear = this.quarterForm?.value?.startingYear;
        this.selectedQuarters.sort();
        if (this.selectedQuarters.length === 1) {
          switch (this.selectedQuarters[0]) {
            case 0: {
              this.setStartDate(
                `${selectedYear}-07-01`,
                `${selectedYear}-04-01`,
              );
              this.setEndDate(
                `${selectedYear}-09-30`,
                `${selectedYear}-06-30`,
              );
              break;
            }
            case 1: {
              this.setStartDate(
                `${selectedYear}-10-01`,
                `${selectedYear}-07-01`,
              );
              this.setEndDate(
                `${selectedYear}-12-31`,
                `${selectedYear}-09-30`,
              );
              break;
            }
            case 2: {
              this.setStartDate(
                `${selectedYear + 1}-01-01`,
                `${selectedYear}-10-01`,
              );
              this.setEndDate(
                `${selectedYear + 1}-03-31`,
                `${selectedYear}-12-31`,
              );
              break;
            }
            case 3: {
              this.setStartDate(
                `${selectedYear + 1}-04-01`,
                `${selectedYear + 1}-01-01`,
              );
              this.setEndDate(
                `${selectedYear + 1}-06-30`,
                `${selectedYear + 1}-03-31`,
              );
              break;
            }
            default: {
              this.setStartDate();
              this.setEndDate();
            }
          }
        } else if (this.selectedQuarters.length === 2) {
          switch (this.selectedQuarters[0]) {
            case 0: {
              this.setStartDate(
                `${selectedYear}-07-01`,
                `${selectedYear}-04-01`,
              );
              this.setEndDate(
                `${selectedYear}-12-31`,
                `${selectedYear}-09-30`,
              );
              break;
            }
            case 1: {
              this.setStartDate(
                `${selectedYear}-10-01`,
                `${selectedYear}-07-01`,
              );
              this.setEndDate(
                `${selectedYear + 1}-03-31`,
                `${selectedYear}-12-31`,
              );
              break;
            }
            case 2: {
              this.setStartDate(
                `${selectedYear + 1}-01-01`,
                `${selectedYear}-10-01`,
              );
              this.setEndDate(
                `${selectedYear + 1}-06-30`,
                `${selectedYear + 1}-03-31`,
              );
              break;
            }
            default: {
              this.setStartDate();
              this.setEndDate();
            }
          }
        } else if (this.selectedQuarters.length === 3) {
          switch (this.selectedQuarters[0]) {
            case 0: {
              this.setStartDate(
                `${selectedYear}-07-01`,
                `${selectedYear}-04-01`,
              );
              this.setEndDate(
                `${selectedYear + 1}-03-31`,
                `${selectedYear}-12-31`,
              );
              break;
            }
            case 1: {
              this.setStartDate(
                `${selectedYear}-10-01`,
                `${selectedYear}-07-01`,
              );
              this.setEndDate(
                `${selectedYear + 1}-06-30`,
                `${selectedYear + 1}-03-31`,
              );
              break;
            }
            default: {
              this.setStartDate();
              this.setEndDate();
            }
          }
        } else if (this.selectedQuarters.length === 4) {
          this.setStartDate(
            `${selectedYear}-07-01`,
            `${selectedYear}-04-01`,
          );
          this.setEndDate(
            `${selectedYear + 1}-06-30`,
            `${selectedYear + 1}-03-31`,
          );
        }
        this.closeDialog();
      }
    } else {
      if (
        this.startingSelectedQuarters !== -1 &&
        this.endingSelectedQuarters !== -1
      ) {
        this.closeDialog();
      }
    }
  }

  /**
   * closing themodal and sending the data to parent component
   */
  private closeDialog(): void {
    this.dialog.close({
      minDate: this.startDate,
      maxDate: this.endDate,
      displayedMinDate: this.displayedStartDate,
      displayedMaxDate: this.displayedEndDate,
      quarterNo: this.selectedQuarters,
      selectedStartYear: this.quarterForm?.value?.startingYear,
      startedYearSelectedQuarter: this.startingSelectedQuarters,
      selectedEndYear: this.quarterForm?.value?.endingYear,
      endingYearSelectedQuarter: this.endingSelectedQuarters,
    });
  }

  public onClose(){
    this.dialog.close();
  }
}
