import { NgModule } from '@angular/core';

import { ContributionTableComponent } from './contribution-table/contribution-table.component';
import { ContributionModalComponent } from './contribution-modal/contribution-modal.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { AdminContributionCategoriesComponent } from './admin-contribution-categories/admin-contribution-categories.component';
import { PipesModule } from '../pipes/pipes.module';
import { AdminNavbarComponent } from './admin-navbar/admin-navbar.component';
import { AdminPortalRoutingModule } from './admin-portal-routing.module';

import { RewardSectionComponent } from './admin-reward-distribution/reward-section/reward-section.component';
import { BudgetingSectionComponent } from './admin-reward-distribution/budgeting-section/budgeting-section.component';
import { MainSectionComponent } from './admin-reward-distribution/main-section/main-section.component';
import { BudgetRewardTabsComponent } from './admin-reward-distribution/budget-reward-tabs/budget-reward-tabs.component';
import { BudgetRewardHistoryTableComponent } from './admin-reward-distribution/budget-reward-history-table/budget-reward-history-table.component';
import { CsvModalComponent } from './admin-reward-distribution/csv-modal/csv-modal.component';
import { DiscountCategoryComponent } from './admin-brand-selection/discount-category/discount-category.component';
import { AllBrandsComponent } from './admin-brand-selection/all-brands/all-brands.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ExcelModalComponent } from './excel-modal/excel-modal.component';
import { ContributionsSectionComponent } from './analytics/contributions-section/contributions-section.component';
import { TransactionsSectionComponent } from './analytics/transactions-section/transactions-section.component';
import { AnalyticDashboardComponent } from './analytics/analytic-dashboard/analytic-dashboard.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransactionLogComponent } from './admin-reward-distribution/transaction-log/transaction-log.component';
import { RollbackModalComponent } from './admin-reward-distribution/rollback-modal/rollback-modal.component';
import { NumberFormatterPipe } from '../pipes/number-formatter.pipe';

@NgModule({
  declarations: [
    ContributionTableComponent,
    ContributionModalComponent,
    AdminDashboardComponent,
    AdminSidebarComponent,
    AdminContributionCategoriesComponent,
    AdminNavbarComponent,
    RewardSectionComponent,
    BudgetingSectionComponent,
    MainSectionComponent,
    BudgetRewardTabsComponent,
    BudgetRewardHistoryTableComponent,
    CsvModalComponent,
    DiscountCategoryComponent,
    AllBrandsComponent,
    ExcelModalComponent,
    AnalyticDashboardComponent,
    ContributionsSectionComponent,
    TransactionsSectionComponent,
    TransactionLogComponent,
    RollbackModalComponent,
  ],
  imports: [
    PipesModule,
    AdminPortalRoutingModule,
    SharedModule,
    NgxMatSelectSearchModule,
    NgbModule,
  ],
  providers:[NumberFormatterPipe],
  exports: [AdminSidebarComponent, AdminNavbarComponent],
})
export class AdminPortalModule {}
