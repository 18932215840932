<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Allotted Budget">
    <ng-template matTabContent>
      <app-budget-reward-history-table
        [flag]="false"
      ></app-budget-reward-history-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="Occasional Rewards">
    <ng-template matTabContent>
      <app-budget-reward-history-table
        [flag]="true"
      ></app-budget-reward-history-table> </ng-template
  ></mat-tab>
  <mat-tab label="Transaction Log">
    <ng-template matTabContent>
      <app-transaction-log>

      </app-transaction-log>
    </ng-template>
  </mat-tab>
</mat-tab-group>
