<div class="p-5 position-relative">
    <img class="cross-icon" role="button" (click)="onCancel()" src="./assets/images/close-icon-rollback-modal.svg"/>
    <div class="d-flex justify-content-center align-items-center">
        <div class="circle"><img class="img" src={{data.img}} /></div>
    </div>
    <mat-dialog-content>
        <div class="text-center text">{{data.title}}</div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-center align-items-center">
        <button class="btn mt-4 mr-3" mat-button (click)="onCancel()">Cancel</button>
        <button class="btn mt-4" mat-button (click)="onSave()" cdkFocusInitial>Yes</button>
    </mat-dialog-actions>
</div>