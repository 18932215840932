<div class="position-relative">
    <div class="alertBox p-3" [ngClass]="{'d-none':!isJobRunning}">
        <div class="container messageBox p-3">
            <img src="./assets/images/hourGlass.png">
            <div class="mt-3">Background process in progress. </div>
            <div>The transaction log will be available shortly.</div>
        </div>
    </div>
    <table #table mat-table [dataSource]="dataSource" class="w-100" multiTemplateDataRows>
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef><mat-checkbox class="checkbox" [checked]="selectAll"
                    (change)="selectAllItems($event)" [disabled]="tableLength==0||isJobRunning"></mat-checkbox></th>
            <td mat-cell *matCellDef="let element;">
                <mat-checkbox [checked]="element.selected" (change)="toggleCheck(element.id,$event)" [disabled]="isJobRunning"></mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="no">
            <th mat-header-cell *matHeaderCellDef>S.No</th>
            <td mat-cell *matCellDef="let element;">
                {{
                this.row * this.tablePageIndex +
                (dataSource.filteredData.indexOf(element) + 1)
                }}.
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                {{ element.email }}
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element">{{ element.amount| number:'1.2-2' }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">{{ element.date }}</td>
        </ng-container>


        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let element">
                {{ element.title }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="!dataSource.data.length" class="no_record center-align">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img src="./assets/images/noTransactionImage.svg" />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">No transaction log found!</div>
        </div>
    </div>
    <div class="d-flex justify-content-between p-3 align-items-center">
        <div class="row flex-grow-1 btn-wrap p-md-2">
            <button class="btn blue-btn col-lg-5 col-12 " (click)="openSendNowModal()" [disabled]="noneSelected||isJobRunning">Send
                Now</button>
            <button class="btn white-btn col-lg-5 ml-lg-3 col-12 mt-2 mt-lg-0" (click)="openMultipleRollbackModal()"
                [disabled]="noneSelected||isJobRunning">Rollback</button>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10]" [pageIndex]="tablePageIndex" (page)="pageEvent = getData($event)"
            [length]="tableLength" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>