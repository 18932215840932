import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { RollbackModalComponent } from '../rollback-modal/rollback-modal.component';
import { AdminPortalService } from '../../service/admin-portal.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PageData } from 'src/app/shared/models/admin-portal.model';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-transaction-log',
  templateUrl: './transaction-log.component.html',
  styleUrls: ['./transaction-log.component.css']
})
export class TransactionLogComponent implements OnInit {
  @ViewChild('table') table!: MatTable<any>;
  dataSource: MatTableDataSource<any>
  row: number;
  tablePageIndex: number;
  pageEvent: PageEvent;
  tableLength: number;
  selectAll: boolean;
  noneSelected: boolean;
  selection = new SelectionModel<number>(true, []);
  isJobRunning: boolean;


  displayedColumns: string[] = [
    "select",
    "no",
    "email",
    "amount",
    "date",
    "title",
  ];

  constructor(private dialog: MatDialog,
    private adminPortalService: AdminPortalService,
    private toastr: ToastrService) {
    this.dataSource = new MatTableDataSource<any>();
    this.row = 0;
    this.tablePageIndex = 0;
    this.tableLength = 0;
    this.selectAll = false;
    this.noneSelected = true;
    this.isJobRunning = false;
    this.pageEvent=new PageEvent();
    this.pageEvent.pageIndex=1;
    this.pageEvent.pageSize=5;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(event?: PageEvent): PageEvent {
    let obj: PageData;
    this.dataSource = new MatTableDataSource<any>();
    if (event) {
      this.row = event!.pageSize;
      this.tablePageIndex = event!.pageIndex;
      obj = {
        pageNo: this.tablePageIndex + 1,
        rowsPerPage: event!.pageSize,
      };
    } else {
      this.row = 5;
      obj = {
        pageNo: 1,
        rowsPerPage: this.pageEvent.pageSize,
      };
      this.tablePageIndex = 0;
    }
    let tableData: any[] = [];
    this.adminPortalService.getTransactionLogs(obj).subscribe(({ data, jobRunning, total_rows }) => {
      if (data) {
        for (let transaction of data) {
          let tableRow = {
            email: transaction.employeeEmail.email,
            amount: transaction.amount,
            date: transaction.stagedAt.substr(0, 10),
            title: transaction.title,
            id: transaction.id,
            selected: this.selection.isSelected(transaction.id)
          }
          tableData.push(tableRow);
        }
      }
      this.dataSource = new MatTableDataSource<any>(tableData);
      this.tableLength = total_rows;
      this.isJobRunning = jobRunning=="FALSE"?false:true;
      this.adminPortalService.setIsJobRunning(this.isJobRunning);
      this.selectAll = this.isAllSelected();
      this.noneSelected = this.isNoneSelected();
    });

    if (event) return event;
    return new PageEvent();
  }

  openSendNowModal() {
    const dialogRef = this.dialog.open(RollbackModalComponent, {
      height: '336px',
      width: '500px',
      data: {
        title: "Do you want to send the allotted amount right now ?",
        img: "./assets/images/send-now-img.svg"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result.action) {
        return;
      }
      this.isJobRunning=true;
      this.adminPortalService.setIsJobRunning(this.isJobRunning);
      this.adminPortalService.sendNowTransactionLogs(this.selection.selected).subscribe(res => {
        this.selection.clear();
        this.successModal("Amount allocated Successfully.");
      });
    });
  }

  openMultipleRollbackModal() {
    const dialogRef = this.dialog.open(RollbackModalComponent, {
      height: '344px',
      width: '500px',
      data: {
        title: "Are you sure you want to roll back these payments? This action cannot be undone.",
        img: "./assets/images/admin-portal-rollback-img.svg"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result.action) {
        return;
      }
      this.isJobRunning=true;
      this.adminPortalService.setIsJobRunning(this.isJobRunning);
      this.adminPortalService.rollbackTransaction(this.selection.selected).subscribe(res => {
        this.selection.clear();
        this.successModal("Payment rolled back Successfully.");
      });
    });
  }

  successModal(message: string) {
    let genericDailogData = {
      title: message,
      img: 'assets/images/n_image/Success.gif',
      heading: "transaction log"
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent,
      {
        height: "320px",
        width: "500px",
        data: genericDailogData
      }
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.getData();
        },
        error: () => {
          this.toastr.error('Error while loading Data');
        }
      });
  }

  selectAllItems(event: any) {
    let obj = {
      pageNo: 1,
      rowsPerPage: this.tableLength,
    };
    this.adminPortalService.getTransactionLogs(obj).subscribe(({ data, total_rows }) => {
      if (data) {
        let ids: number[] = [];
        for (let transaction of data) {
          ids.push(transaction.id);
        }
        if (event.checked) {
          this.selection.select(...ids);
        } else {
          this.selection.deselect(...ids);
        }
        this.dataSource.data.map((element) => { element.selected = event.checked });
        this.selectAll = event.checked;
        this.noneSelected = !event.checked;
      }
    });
  }

  toggleCheck(id: number, event: any) {
    let idx = this.dataSource.data.findIndex((element) => element.id == id)
    this.dataSource.data[idx].selected = event.checked;
    if (event.checked) {
      this.selection.select(id);
    } else {
      this.selection.deselect(id);
    }
    this.selectAll = this.isAllSelected();
    this.noneSelected = this.isNoneSelected();
  }

  isAllSelected = () => {
    return this.tableLength != 0 && this.selection.selected.length == this.tableLength;
  }

  isNoneSelected = () => {
    return this.selection.isEmpty();
  }
}
